import { Component, computed, inject, Signal } from '@angular/core';
import { EventSourceService } from '../../services/event-source.service';
import { GameMode } from '../../models';
import { AdsComponent } from "../ads/ads.component";

@Component({
  selector: 'app-pre-fight',
  standalone: true,
  imports: [AdsComponent],
  templateUrl: './pre-fight.component.html',
  styleUrl: './pre-fight.component.scss'
})
export class PreFightComponent {
  private eventSourceService = inject(EventSourceService);
  public gameMode: Signal<GameMode> = this.eventSourceService.currentMode;
}
