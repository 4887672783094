import { Component } from '@angular/core';
import { CreatorComponent } from "../creator/creator.component";

@Component({
  selector: 'app-thanks',
  standalone: true,
  imports: [CreatorComponent],
  templateUrl: './thanks.component.html',
  styleUrl: './thanks.component.scss'
})
export class ThanksComponent {

}
