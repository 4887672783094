<div class="app-voting">
    <div class="health margin-bottom">
        <div class="information boss unselectable">
            <p>Boss ({{health().boss_health_current}}/{{health().boss_health_max}})</p>
            <div class="addons">
                @for(addon of bossAddons(); track addon) {
                <div class="addon"><img [src]="addon"></div>
                }
            </div>
        </div>
        <div class="progress">
            <div class="bar striped {{bossHealthState()}} w-{{bossHealthPercentage()}}"></div>
        </div>
    </div>

    <div class="container votes">
        @for(votingOption of votingOptions(); track votingOption.name) {
        <button class="paper-btn btn-block" [disabled]="votingOption.disabled || hasVoted() || gameAction() !== gameActions.VOTING" [ngClass]="{
                'btn-danger-outline': votingOption.fraktion === fraktion.SOLARIS && !hasVoted(),
                'btn-secondary-outline': votingOption.fraktion === fraktion.NOX && !hasVoted(),
                'btn-success': hasVoted() && userVote() === votingOption.id,
                }" (click)="vote(votingOption)">
            {{ votingOption.name }} ({{ votingOption.description }})
        </button>
        }
    </div>

    <div class="health margin-top">
        <div class="progress">
            <div class="bar striped {{heroHealthState()}} w-{{heroHealthPercentage()}}"></div>
        </div>
        <div class="information hero unselectable">
            <p>
                @if(eastereggUrlBarrex() && eastereggUrlTroy()) {
                <img [src]="eastereggUrlBarrex()" alt="Hero" id="heroimage">
                <img [src]="eastereggUrlTroy()" alt="Hero" id="heroimage">
                } @else {
                <span (click)="easteregg()" id="heroname">
                    @if(gameMode() === gameModes.HELIA) {
                        Helia
                    } @else if (gameMode() === gameModes.NICO) {
                        Nico
                    } @else {
                        Helden
                    }
                </span>
                }
                ({{health().hero_health_current}}/{{health().hero_health_max}})
            </p>
            <div class="addons">
                @for(addon of heroAddons(); track addon) {
                <div class="addon"><img [src]="addon"></div>
                }
            </div>
        </div>
    </div>
</div>