<div class="container">
    <div class="afterfight">
        @if(winner() === 'HERO') {
            @switch (gameMode()) {
                @case ('HELIA') {
                    <p>HELIA war siegreich!</p>
                    <img src="../../../assets/heros/helia.png" class="no-border">
                }
                @case ('NICO') {
                    <p>NICO war siegreich!</p>
                    <img src="../../../assets/heros/nico.png" class="no-border">
                }
                @default {
                    <p>NICO & HELIA waren siegreich!</p>
                    <img src="../../../assets/heros/main.png" class="no-border">
                }
            }
        } @else if (winner() === 'BOSS') {
            @switch (gameMode()) {
                @case ('HELIA') {
                    <p>JUGGERNAUT war zu stark!</p>
                    <img src="../../../assets/boss/miniboss2.gif" class="no-border">
                }
                @case ('NICO') {
                    <p>CALAMITY war zu stark!</p>
                    <img src="../../../assets/boss/miniboss1.gif" class="no-border">
                }
                @default {
                    <p>RUIN war zu stark!</p>
                    <img src="../../../assets/boss/main.gif" class="no-border">
                }
            }
        } @else {
            <p>Kein Sieger?! ... Kennst du Franziskus?</p>
            <img src="../../../assets/easteregg/franziskus.png" class="no-border">
        }
    </div>
    <app-ads></app-ads>
</div>