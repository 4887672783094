import { Component, computed, inject, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { Vote } from '../../models/old/del_vote.model';
import { Fraktion } from '../../models/old/del_fraktion.enum';
import { NgClass } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { GameAction, GameMode } from '../../models';
import { EventSourceService } from '../../services/event-source.service';
import { GameStats } from '../../models/gameStats.model';
import { VoteService } from '../../services/vote.service';
import { GameEffects } from '../../models/gameEffects.model';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-voting',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './voting.component.html',
  styleUrl: './voting.component.scss'
})
export class VotingComponent implements OnInit {

  private toastService = inject(ToastrService);
  private eventSourceService = inject(EventSourceService);
  private voteService = inject(VoteService);
  private cookieService = inject(CookieService);

  public gameAction: WritableSignal<GameAction> = this.eventSourceService.currentAction;
  public gameActions = GameAction;

  public fraktion = Fraktion;

  public gameMode = this.eventSourceService.currentMode;
  public gameModes = GameMode;

  private clickCount = 0;
  private timeoutId: any;
  private readonly timeLimit = 300;  // Time limit in milliseconds (e.g., 2 seconds)
  private readonly maxClicks = 5;

  public votingOptions: Signal<Vote[]> = computed(() => {
    const gameMode = this.gameMode();
    return this.setAttacks(gameMode);
  });

  public eastereggUrlBarrex: WritableSignal<string | undefined> = signal(undefined);
  public eastereggUrlTroy: WritableSignal<string | undefined> = signal(undefined);

  public health: Signal<GameStats> = this.eventSourceService.currentStats;

  public gameID: Signal<string> = this.eventSourceService.gameID;
  public gameRound: Signal<number> = this.eventSourceService.gameRound;

  public userGameID: WritableSignal<string | undefined> = signal(undefined);
  public userGameRound: WritableSignal<number | undefined> = signal(undefined);
  public userVote: WritableSignal<number | undefined> = signal(undefined);

  public hasVoted: Signal<boolean> = computed(() => {
    const gameId = this.gameID();
    const gameRound = this.gameRound();

    const userGameId = this.userGameID();
    const userGameRound = this.userGameRound();

    if (userGameId === undefined || userGameRound === undefined) {
      return false;
    }

    return gameId === userGameId && gameRound === userGameRound;
  });

  public bossAddons: Signal<string[]> = computed(() => {
    const effects: GameEffects = this.health().boss_effects;

    return this.getAddons(effects);
  });

  public heroAddons: Signal<string[]> = computed(() => {
    const effects: GameEffects = this.health().hero_effects;

    return this.getAddons(effects);
  });

  public heroHealthPercentage = computed(() => {
    const current = this.health().hero_health_current;
    const maximum = this.health().hero_health_max;

    return this.getRoundedPercentage(current, maximum);
  });

  public bossHealthPercentage = computed(() => {
    const current = this.health().boss_health_current;
    const maximum = this.health().boss_health_max;

    return this.getRoundedPercentage(current, maximum);
  });

  public heroHealthState = computed(() => {
    const percentage = this.heroHealthPercentage();
    return this.getHealthState(percentage);
  });

  public bossHealthState = computed(() => {
    const percentage = this.bossHealthPercentage();
    return this.getHealthState(percentage);
  });

  public ngOnInit(): void {
    const gameMode = this.gameMode();
    this.userGameID.set(this.cookieService.get(`${gameMode}_gameid`));
    this.userGameRound.set(Number(this.cookieService.get(`${gameMode}_round`)));
    this.userVote.set(Number(this.cookieService.get(`${gameMode}_vote`)));
  }

  public easteregg(): void {
    this.clickCount++;

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);  // Clear previous timeout
    }

    // Set a timeout that resets the click count after the specified time limit
    this.timeoutId = setTimeout(() => {
      this.clickCount = 0;
    }, this.timeLimit);

    // If the function is triggered 5 times within the time limit, call the other function
    if (this.clickCount >= this.maxClicks) {
      this.showEasteregg();
      this.clickCount = 0;  // Reset click count after calling the other function
    }
  }

  private showEasteregg(): void {
    const heronameElement = document.getElementById('heroname');
    if (heronameElement) {
      this.eastereggUrlBarrex.set('assets/easteregg/barrex.gif');
      this.eastereggUrlTroy.set('assets/easteregg/troy.gif');

      setTimeout(() => {
        this.eastereggUrlBarrex.set(undefined);
        this.eastereggUrlTroy.set(undefined);
      }, 6000);
    }
  }

  public vote(voting: Vote): void {
    const gameMode = this.gameMode();
    const gameId = this.gameID();
    const gameRound = this.gameRound();
    const votedId = voting.id;

    this.voteService.vote(votedId.toString(), gameMode).subscribe({
      next: () => {

        this.cookieService.set(`${gameMode}_vote`, votedId.toString());
        this.cookieService.set(`${gameMode}_gameid`, gameId);
        this.cookieService.set(`${gameMode}_round`, gameRound.toString());
        
        this.userVote.set(votedId);
        this.userGameID.set(gameId);
        this.userGameRound.set(gameRound);

        // this.toastService.success('Deine Stimme wurde gezählt!', 'Yay!');
      },
      error: () => {
        this.toastService.error('Deine Stimme konnte nicht gegeben werden!', 'Oh nein!');
      }
    });
  }

  private getAddons(effects: GameEffects) {
    let addons: string[] = [];
    if (effects.shield) {
      addons.push('assets/addons/shield.png');
    }

    if (effects.buff) {
      addons.push('assets/addons/buff.png');
    }

    if (effects.nexus) {
      addons.push('assets/addons/nexus.png');
    }

    if (effects.stun) {
      addons.push('assets/addons/stun.png');
    }

    if (effects.counter) {
      addons.push('assets/addons/counter.png');
    }

    if (effects.wound) {
      addons.push('assets/addons/wound.png');
    }

    if (effects.overclock) {
      addons.push('assets/addons/overclock.png');
    }

    if (effects.aquatic) {
      addons.push('assets/addons/aquatic.png');
    }

    if (effects.lightning) {
      addons.push('assets/addons/lightning.png');
    }

    return addons;
  }

  private getRoundedPercentage(current: number, maximum: number): number {
    const rounded = Math.round((current / maximum) * 100);
    const percentage = Math.round(rounded / 5) * 5;

    return percentage > 0 ? percentage > 100 ? 100 : percentage : 0;
  }

  private getHealthState(percentage: number): string {
    if (percentage > 70) {
      return 'success';
    }

    if (percentage <= 70 && percentage > 25) {
      return 'warning';
    }

    return 'danger';
  }

  private setAttacks(gameMode: GameMode): Vote[] {
    switch (gameMode) {
      case GameMode.HELIA:
        return this.setHeliaAttacks();
      case GameMode.NICO:
        return this.setNicoAttacks();
      case GameMode.MAIN:
        return this.setMainAttacks();
    }
  }

  private setHeliaAttacks(): Vote[] {
    return [
      {
        id: 1,
        name: 'Arkanes Geschoss',
        disabled: false,
        fraktion: Fraktion.SOLARIS,
        description: 'Leichter Angriff'
      },
      {
        id: 2,
        name: 'Phönix Inferno',
        disabled: false,
        fraktion: Fraktion.SOLARIS,
        description: 'Schwerer Angriff'
      },
      {
        id: 3,
        name: 'Arkane Regeneration',
        disabled: false,
        fraktion: Fraktion.SOLARIS,
        description: 'Heilung'
      },
      {
        id: 4,
        name: 'Eiswall',
        disabled: false,
        fraktion: Fraktion.SOLARIS,
        description: 'Schutz'
      },
    ];
  }

  private setNicoAttacks(): Vote[] {
    return [
      {
        id: 5,
        name: 'Wolfsklinge',
        disabled: false,
        fraktion: Fraktion.NOX,
        description: 'Leichter Angriff'
      },
      {
        id: 6,
        name: 'Pulsgranate',
        disabled: false,
        fraktion: Fraktion.NOX,
        description: 'Schwerer Angriff'
      },
      {
        id: 7,
        name: 'Nanobots',
        disabled: false,
        fraktion: Fraktion.NOX,
        description: 'Heilung'
      },
      {
        id: 8,
        name: 'Hardlicht Barriere',
        disabled: false,
        fraktion: Fraktion.NOX,
        description: 'Schutz'
      },
    ];
  }

  private setMainAttacks(): Vote[] {
    return [
      {
        id: 1,
        name: 'Arkanes Geschoss',
        disabled: false,
        fraktion: Fraktion.SOLARIS,
        description: 'Leichter Angriff'
      },
      {
        id: 6,
        name: 'Pulsgranate',
        disabled: false,
        fraktion: Fraktion.NOX,
        description: 'Schwerer Angriff'
      },
      {
        id: 7,
        name: 'Nanobots',
        disabled: false,
        fraktion: Fraktion.NOX,
        description: 'Heilung'
      },
      {
        id: 4,
        name: 'Eiswall',
        disabled: false,
        fraktion: Fraktion.SOLARIS,
        description: 'Schutz'
      },
      {
        id: 9,
        name: 'Gunst der Wächter',
        disabled: false,
        fraktion: Fraktion.NEUTRAL,
        description: 'Mystery'
      },
    ];
  }
}
