import { Component, inject, Signal } from '@angular/core';
import { AdsComponent } from "../ads/ads.component";
import { EventSourceService } from '../../services/event-source.service';
import { GameMode } from '../../models';

@Component({
  selector: 'app-after-fight',
  standalone: true,
  imports: [AdsComponent],
  templateUrl: './after-fight.component.html',
  styleUrl: './after-fight.component.scss'
})
export class AfterFightComponent {
  private eventSourceService = inject(EventSourceService);
  public gameMode: Signal<GameMode> = this.eventSourceService.currentMode;
  public winner: Signal<string> = this.eventSourceService.winner;
}
