import { HttpClient } from '@angular/common/http';
import { Injectable, Signal, WritableSignal, computed, inject, signal } from '@angular/core';
import { Subject } from 'rxjs';
import { GameAction, GameMode, GameStatus } from '../models';
import { GameEvent } from '../models/gameEvent.model';
import { GameStats } from '../models/gameStats.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventSourceService {
  private HELIA_API = environment.apiUrl + 'sse/helia';
  private NICO_API = environment.apiUrl + 'sse/nico';
  private MAIN_API = environment.apiUrl + 'sse/main';

  private sseSource!: EventSource;
  private http = inject(HttpClient);
  private gameSubject$ = new Subject<GameEvent>();

  private gameStatus = GameStatus;
  public currentStatus: WritableSignal<GameStatus> = signal(this.gameStatus.LOADING);

  private gameAction = GameAction;
  public currentAction: WritableSignal<GameAction> = signal(this.gameAction.VOTING);

  private gameMode = GameMode;
  public currentMode: WritableSignal<GameMode> = signal(this.gameMode.MAIN);

  public showMatrix: WritableSignal<boolean> = signal(false);

  public gameID: WritableSignal<string> = signal('');
  public gameRound: WritableSignal<number> = signal(0);

  public winner: Signal<string> = computed(() => {
    const gameStats = this.currentStats();

    return gameStats.winner;
  });

  public currentStats: WritableSignal<GameStats> = signal({
    hero_health_current: 0,
    hero_health_max: 0,
    hero_effects: {
      shield: false,
      buff: false,
      nexus: false,
      stun: false,
      counter: false,
      wound: false,
      overclock: false,
      aquatic: false,
      lightning: false,
    },
    boss_health_current: 0,
    boss_health_max: 0,
    boss_effects: {
      shield: false,
      buff: false,
      nexus: false,
      stun: false,
      counter: false,
      wound: false,
      overclock: false,
      aquatic: false,
      lightning: false,
    },
    winner: 'HERO'
  });

  public heliaStart(): void {
    this.startSse(this.HELIA_API);
  }

  public heliaStop(): void {
    this.stopSse(this.HELIA_API);
  }

  public nicoStart(): void {
    this.startSse(this.NICO_API);
  }

  public nicoStop(): void {
    this.stopSse(this.NICO_API);
  }

  public mainStart(): void {
    this.startSse(this.MAIN_API);
  }

  public mainStop(): void {
    this.stopSse(this.MAIN_API);
  }

  public startSse(api: string): void {
    if (this.sseSource !== undefined) {
      return;
    }
    this.sseSource = new EventSource(`${api}`);
    this.getFeed();
  }

  public stopSse(api: string): void {
    if (this.sseSource === undefined) {
      return;
    }
    this.http.post(`${api}/stop`, {}).subscribe();
    this.sseSource.close();
  }

  private getFeed(): void {
    this.sseSource.addEventListener('message', (e: MessageEvent) => {
      const data = JSON.parse(e.data);

      this.currentAction.set(data.gameAction);
      this.currentMode.set(data.gameMode);
      this.currentStatus.set(data.gameStatus);
      this.currentStats.set(data.gameStats);
      this.showMatrix.set(data.showMatrix);
      this.gameID.set(data.gameID);
      this.gameRound.set(data.gameRound);
      this.gameSubject$.next(data);
    });

    this.sseSource.onerror = () => {
      console.error('😭 sse error');
    };
  }
}
