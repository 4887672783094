<div class="container">
    <div class="prefight">
        @switch (gameMode()) {
        @case ('HELIA') {
        <div class="helia"></div>
        <h2>Helia</h2>
        <h5>braucht deine Unterstützung!</h5>
        <p>Komm zum <b><u>Stand A01</u></b> in der <b><u>Halle 3.2</u></b> und hilf mit, den Lakaie des Bosses zu
            vernichten!</p>
        }
        @case ('NICO') {
        <div class="nico"></div>
        <h2>Nico</h2>
        <h5>braucht deine Unterstützung!</h5>
        <p>Komm zum <b><u>Stand A01</u></b> in der <b><u>Halle 3.2</u></b> und hilf mit, den Lakaie des Bosses zu
            vernichten!</p>
        }
        @default {
        <div class="main"></div>
        <h2>Nico & Helia</h2>
        <h5>brauchen deine Unterstützung!</h5>
        <p>Komm zur <b><u>MAINSTAGE E05</u></b> in der <b><u>Halle 2.2</u></b> und hilf mit, den Boss zu vernichten!</p>
        }
        }
    </div>
    <app-ads></app-ads>
</div>