import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { GameMode } from '../models';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VoteService {
  private API = environment.apiUrl + 'herofest/vote';
  private http = inject(HttpClient);

  public vote(voteId: string, gameMode: GameMode): Observable<unknown> {
    return this.http.post(`${this.API}`, {
      voteId,
      gameMode,
    }, { withCredentials: true });
  }
}
