import { Component, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PreFightComponent } from '../pre-fight/pre-fight.component';
import { AfterFightComponent } from '../after-fight/after-fight.component';
import { VotingComponent } from '../voting/voting.component';
import { EventSourceService } from '../../services/event-source.service';
import { GameStatus } from '../../models';
import { SplashscreenComponent } from "../splashscreen/splashscreen.component";
import { NightComponent } from "../night/night.component";
import { ThanksComponent } from "../thanks/thanks.component";
import { MatrixComponent } from "../matrix/matrix.component";
import { CommonModule } from '@angular/common';
import { LoadingComponent } from "../loading/loading.component";

@Component({
  selector: 'app-welcome',
  standalone: true,
  imports: [
    PreFightComponent,
    AfterFightComponent,
    VotingComponent,
    SplashscreenComponent,
    NightComponent,
    ThanksComponent,
    MatrixComponent,
    CommonModule,
    LoadingComponent
],
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.scss'
})
export class WelcomeComponent implements OnInit {
  private eventSourceService = inject(EventSourceService);
  private route = inject(ActivatedRoute);
  
  public gameStatus = GameStatus;
  public currentStatus: WritableSignal<GameStatus> = this.eventSourceService.currentStatus;
  public gameMode: WritableSignal<string> = signal('main');
  public showMatrix: WritableSignal<boolean> = this.eventSourceService.showMatrix;

  public ngOnInit(): void {
    const gameMode = this.route.snapshot.queryParamMap.get('mode');

    if (gameMode === 'helia') {
      this.gameMode.set('helia');
      this.eventSourceService.heliaStart();
    } else if (gameMode === 'nico') {
      this.gameMode.set('nico');
      this.eventSourceService.nicoStart();
    } else {
      this.gameMode.set('main');
      this.eventSourceService.mainStart();
    }
  }
}
