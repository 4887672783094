import { Component } from '@angular/core';
import { AdsComponent } from "../ads/ads.component";

@Component({
  selector: 'app-night',
  standalone: true,
  imports: [AdsComponent],
  templateUrl: './night.component.html',
  styleUrl: './night.component.scss'
})
export class NightComponent {

}
