import { Component } from '@angular/core';

type Link = {
  name: string;
  url: string;
  icon: string;
}

type Creator = {
  name: string;
  image: string;
  backgroundImage: string;
  description: string;
  links: Link[];
}

@Component({
  selector: 'app-creator',
  standalone: true,
  imports: [],
  templateUrl: './creator.component.html',
  styleUrl: './creator.component.scss'
})
export class CreatorComponent { 
}
