import { Component } from '@angular/core';
import { AdsComponent } from "../ads/ads.component";

@Component({
  selector: 'app-loading',
  standalone: true,
  imports: [AdsComponent],
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss'
})
export class LoadingComponent {

}
