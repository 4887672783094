import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-splashscreen',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './splashscreen.component.html',
  styleUrl: './splashscreen.component.scss'
})
export class SplashscreenComponent implements OnInit {

  windowWidth: string = "";
  splashTransition: string = "";
  opacityChange: number = 1;
  showSplash = true;

  @Input() animationDuration: number = 0.5;
  @Input() duration: number = 3;
  @Input() mode: string = 'main';

  ngOnInit(): void {
    setTimeout(() => {
      this.opacityChange = 0;

      this.splashTransition = "opacity " + this.animationDuration + "s";

      setTimeout(() => {
        this.showSplash = !this.showSplash;
      }, this.animationDuration * 1000);
    }, this.duration * 1000);
  }

  /*
  private route = inject(ActivatedRoute);

  public gameMode: WritableSignal<string> = signal('main');

  public ngOnInit(): void {
    const gameMode = this.route.snapshot.queryParamMap.get('mode');

    if (gameMode === 'helia') {
      this.gameMode.set('helia');
    } else if (gameMode === 'nico') {
      this.gameMode.set('nico');
    } else {
      this.gameMode.set('main');
    }
  }
    */
}
