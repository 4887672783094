import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-matrix',
  standalone: true,
  imports: [],
  templateUrl: './matrix.component.html',
  styleUrl: './matrix.component.scss'
})
export class MatrixComponent implements AfterViewInit {

  private canvas!: HTMLCanvasElement;
  private ctx!: CanvasRenderingContext2D;
  private canvas2!: HTMLCanvasElement;
  private ctx2!: CanvasRenderingContext2D;
  private cw!: number;
  public ch!: number;
  public charArr = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];
  private fallingCharArr: Point[] = [];
  public fontSize = 4;
  private maxColums!: number;
  
  ngAfterViewInit(): void {
    // Access the canvas elements after view initialization
    this.canvas = document.getElementById('canvas') as HTMLCanvasElement;
    this.ctx = this.canvas.getContext('2d')!;
    this.canvas2 = document.getElementById('canvas2') as HTMLCanvasElement;
    this.ctx2 = this.canvas2.getContext('2d')!;

    // Full screen dimensions
    this.cw = window.innerWidth;
    this.ch = window.innerHeight;
    this.maxColums = this.cw / this.fontSize;
    this.canvas.width = this.canvas2.width = this.cw;
    this.canvas.height = this.canvas2.height = this.ch;

    // Initialize the falling characters
    for (let i = 0; i < this.maxColums; i++) {
      this.fallingCharArr.push(new Point(i * this.fontSize, this.randomFloat(-500, 0), this));
    }

    // Start the animation
    this.update();
  }

  // Update method for screen resizing
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.cw = window.innerWidth;
    this.ch = window.innerHeight;
    this.canvas.width = this.canvas2.width = this.cw;
    this.canvas.height = this.canvas2.height = this.ch;
    this.maxColums = this.cw / this.fontSize;
    this.fallingCharArr = [];
    for (let i = 0; i < this.maxColums; i++) {
      this.fallingCharArr.push(new Point(i * this.fontSize, this.randomFloat(-500, 0), this));
    }
  }

  public randomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min) + min);
  }

  public randomFloat(min: number, max: number): number {
    return Math.random() * (max - min) + min;
  }

  // The main animation loop
  private update = () => {
    this.ctx.fillStyle = "rgba(0, 0, 0, 0.05)";
    this.ctx.fillRect(0, 0, this.cw, this.ch);

    this.ctx2.clearRect(0, 0, this.cw, this.ch);

    for (let i = 0; i < this.fallingCharArr.length; i++) {
      this.fallingCharArr[i].draw(this.ctx, this.ctx2);
    }

    requestAnimationFrame(this.update);
  }
}

class Point {
  private x: number;
  private y: number;
  private speed: number;
  private value: string;

  constructor(x: number, y: number, private component: MatrixComponent) {
    this.x = x;
    this.y = y;
    this.speed = this.component.randomFloat(1, 5);
    this.value = this.component.charArr[this.component.randomInt(0, this.component.charArr.length - 1)].toUpperCase();
  }

  draw(ctx: CanvasRenderingContext2D, ctx2: CanvasRenderingContext2D) {
    // Draw the character in the second canvas
    ctx2.fillStyle = "rgba(255, 255, 255, 0.8)";
    ctx2.font = this.component.fontSize + "px san-serif";
    ctx2.fillText(this.value, this.x, this.y);

    // Draw the character in the first canvas
    ctx.fillStyle = "#0F0";
    ctx.font = this.component.fontSize + "px san-serif";
    ctx.fillText(this.value, this.x, this.y);

    // Update the position
    this.y += this.speed;
    if (this.y > this.component.ch) {
      this.y = this.component.randomFloat(-100, 0);
      this.speed = this.component.randomFloat(2, 5);
    }
  }
}
