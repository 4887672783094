<app-splashscreen [mode]="gameMode()" [duration]="3" [animationDuration]="0.5"></app-splashscreen>
<app-matrix [ngClass]="{'fade-in': showMatrix(), 'fade-out': !showMatrix()}"></app-matrix>
<div class="content {{gameMode()}}">
    @switch (currentStatus()) {

    @case(gameStatus.PRE_FIGHT) {
    <app-pre-fight></app-pre-fight>
    }

    @case(gameStatus.VOTING){
    <app-voting></app-voting>
    }

    @case(gameStatus.AFTER_FIGHT){
    <app-after-fight></app-after-fight>
    }

    @case(gameStatus.NIGHT){
    <app-night></app-night>
    }

    @case(gameStatus.THANKS){
    <app-thanks></app-thanks>
    }

    @default {
        <app-loading></app-loading>
    }
    }
</div>