import { Component, OnDestroy, signal, WritableSignal } from '@angular/core';
import { interval, timeInterval } from 'rxjs';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
  selector: 'app-ads',
  standalone: true,
  imports: [],
  templateUrl: './ads.component.html',
  styleUrl: './ads.component.scss',
  animations: [
    trigger('animate', [
      state('*', style({ opacity: 1 })),

      transition('void => *', [
        style({ opacity: 0 }),
        animate(700),
      ]),

      transition(
        '* => void',
        animate(700, style({ opacity: 0 }))
      ),
    ]),
  ],
})
export class AdsComponent implements OnDestroy {
  private intervalSource = interval(5700);
  private subscription = this.intervalSource.subscribe(() => this.showAd());
  private randomStart = Math.floor(Math.random() * 2) + 1;
  public currentElement: WritableSignal<number> = signal(this.randomStart);

  private showAd(): void {
    const currentNumber = this.currentElement();

    this.currentElement.set(0);

    setTimeout(() => {
      if (currentNumber === 3) {
        this.currentElement.set(1);
      } else {
        this.currentElement.set(currentNumber + 1);
      }
    }, 800);


  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
