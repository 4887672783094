@if(showSplash) {
<div class="app-splash-screen" [ngStyle]="{ 
    'left': windowWidth, 
    'opacity': opacityChange,
    'transition': splashTransition
  }">
    <div class="app-splash-inner">
        <div class="herofestlogo"></div>
        <div class="app-logo {{mode}}"></div>
        <div class="app-loader"></div>
    </div>
</div>
}