import { Routes } from '@angular/router';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { CreatorComponent } from './components/creator/creator.component';

export const routes: Routes = [
    {
        path: '',
        component: WelcomeComponent
    },
    {
        path: 'creator',
        component: CreatorComponent
    },
    {
        path: '**',
        redirectTo: ''
    }
];
