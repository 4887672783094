<div class="container">
    @if(currentElement() === 1) {
    <a href="https://short.barrex.stream/troy" target="_blank" @animate>
        <img class="no-border" src="../../../assets/ads/Banner_Troy.png">
    </a>
    } @else if (currentElement() === 2) {
    <a href="https://short.barrex.stream/twitch" target="_blank" @animate>
        <img class="no-border" src="../../../assets/ads/Banner_Barrex.png">
    </a>
    } @else if (currentElement() === 3) {
    <a href="https://short.barrex.stream/chromatized" target="_blank" @animate>
        <img class="no-border" src="../../../assets/ads/Banner_Chromatized.png">
    </a>
    }
</div>